export const lawyer = {
    lawyer01: {
        title: "윤영환",
        titleEn: "Yoon Younghwan",
        info: [
            {
                title: "약력",
                infoDetail: [
                    {
                        txt: "법무법인 태성 대표변호사",
                        bold: true,
                    },
                    "고려대학교 졸업 (건설 공학 전공)",
                    {
                        txt: "건축시공기술사",
                        bold: true,
                    },
                    "토목기사",
                    "대한변호사협회 건설전문변호사",
                    "대한변호사협회 재개발·재건축전문변호사",
                    "전) 국회 하자 정책토론위원",
                    "전) 한국건설기술연구원 시민감사관",
                    "전) 건설이코노미뉴스 칼럼니스트 ",
                    "네이버 지식iN 건설 전문가 상담 위원",
                    "공동주택하자소송 400여 건 담당",
                    "MBC 등 언론사 법률자문 제공",
                    "대한주택관리사협회 경기도회 강의",
                    "대한주택관리사협회 광주시회 강의",
                    //
                ],
            },
        ],
        ceo: true,
    },

    lawyer02: {
        title: "구찬회",
        titleEn: "Koo Chanhoe",
        comment: "<span class='primary--text'>정비사업·건설사업에 관한 깊은 이해</span>를 바탕으로 언제나 신속하고 실질적인 해결책을 제시하겠습니다.",
        info: [
            {
                title: "약력",
                infoDetail: [
                    "정비사업팀 팀장 변호사",
                    "대한변호사협회 재개발·재건축 전문 변호사",
                    "대한변호사협회 건설 전문 변호사",
                    "정비사업 담당 변호사",
                    "아파트 하자 소송 담당 변호사",
                    "입주예정자협의회 담당 변호사",
                    "현) 한국주택경제 칼럼니스트",
                    //
                ],
            },
            {
                title: "주요 업무 수행사례",
                infoDetail: [
                    "부산·경남 소재 정비사업조합, 시공사 해지 관련 가처분 ",
                    "부산·경남 소재 정비사업조합, 총회효력정지가처분",
                    "부산·경남 소재 정비사업조합, 총회/대의원회 개최금지가처분",
                    "조합 임원 직무집행정지가처분",
                    "시공자·수급인지위확인 소송",
                    "관리처분계획 취소·무효 소송",
                    "총회결의무효확인 소송",
                    "정비사업 관련 공사대금, 용역대금, 손해배상 소송",
                    "도시정비법위반죄 등 정비사업 관련 조합 임원 형사사건, 수사단계 종결",
                    "집합건물법상 관리단 분쟁 가처분 및 소송",
                    //
                ],
            },
        ],
    },

    lawyer03: {
        title: "정우중",
        titleEn: "Jung Woojoong",
        info: [
            {
                title: "약력",
                infoDetail: [
                    "대한변호사협회 등록 건설전문변호사",
                    "전남개발공사 계약심의위원",
                    "광주, 전북 연구개발 특구진흥재단 자문",
                    "광주지방경찰청 인권위원",
                    "광주광역시 행정심판위원, 공유재산심의위원",
                    "전남대학교 법과대학, 법학전문대학원 졸업",
                    //
                ],
            },
        ],
    },

    lawyer04: {
        title: "임정훈",
        titleEn: "lim Junghoon",
        info: [
            {
                title: "약력",
                infoDetail: [
                    "대한변호사협회 등록 건설전문변호사",
                    "대한변호사협회 등록 금융전문변호사",
                    "광주은행 자문변호사",
                    "한국자산관리공사 자문변호사",
                    "광주광역시 공기관 자문변호사",
                    "광주광역시 환경관리공단 이사",
                    "광주지방법원 법인파산관재인",
                    "전) 변호사 노로·임정훈 법률사무소",
                    "전) 한국자산관리공사 근무",
                    //
                ],
            },
        ],
    },

    lawyer05: {
        title: "남덕호",
        titleEn: "Nam Deokho",
        info: [
            {
                title: "약력",
                infoDetail: [
                    "아파트하자소송 담당 변호사",
                    "입주예정자협의회 법률 지원 변호사",
                    "연세대학교 법학전문대학원",
                    "인천지방법원 실무 수습",
                    "정부법무공단 실무 수습",
                    //
                ],
            },
        ],
    },

    lawyer06: {
        title: "최유나",
        titleEn: "Choi Yuna",
        info: [
            {
                title: "약력",
                infoDetail: [
                    {
                        txt: "법무법인 태성 대표변호사",
                        bold: true,
                    },
                    "아파트생활법률상담변호사",
                    "한국심리학회 자문 변호사",
                    "SBS 법정드라마 ‘굿파트너‘ 집필",
                    "25만 명 구독 법률인스타툰 <메리지레드> 연재",
                    "베스트셀러 <혼자와 함께 사이>, <우리 이만 헤어져요> 집필",
                    "tvN ‘유퀴즈 온더 블록‘ 이혼전문변호사로 출연",
                    "CBS ‘세상을 바꾸는 시간 15분’ 강연 200만 뷰 달성",
                    "SBS ‘돌싱포맨’, KBS ‘아침마당’ 등 다수 방송 출연",
                    "前 네이버 연애결혼, 법률판 자문",
                    "前 매일경제 칼럼리스트",
                    "그 외 CBS ‘시사자키’, 언론사 ‘주간동아’, ‘여성동아’, ‘한국일보’, ‘조선일보’ 인터뷰 진행"
                    //
                ],
            },
        ],
        ceo: true,
    },

    lawyer07: {
        title: "문종하",
        titleEn: "Moon Jongha",
        info: [
            {
                title: "약력",
                infoDetail: [
                    "대한변호사협회 건설전문변호사",
                    "건설이코노미뉴스 칼럼니스트",
                    "행정심판 법률지원 변호사",
                    "중소기업 법률자문단",
                    "아파트 법률문제 자문 변호사",
                    "아파트 하자보수 소송 수행",
                    "디자인회사 등 자문 변호사",
                    "전) 법무법인 서우",
                    //
                ],
            },
        ],
    },

    lawyer08: {
        title: "이준희",
        titleEn: "Lee Junhee",
        info: [
            {
                title: "약력",
                infoDetail: [
                    "대한변호사협회 건설전문변호사",
                    "아파트하자소송 법률지원 변호사",
                    "아파트 법률지원 변호사",
                    "제47회 세무사 시험 합격",
                    "서울시립대학교 법학전문대학원 졸업",
                    "전) 법무법인 청조 실무 수습",
                    "전) 법무법인 선한",
                    //
                ],
            },
        ],
    },

    lawyer09: {
        title: "김태은",
        titleEn: "Kim Taeeun",
        info: [
            {
                title: "약력",
                infoDetail: [
                    "대한변호사협회 건설전문변호사",
                    "아파트하자소송 법률지원 변호사",
                    "입주예정자협의회 법률지원 변호사",
                    "아파트 법률문제 자문 변호사",
                    "전) 법무법인 운율 소속 변호사",
                    //
                ],
            },
        ],
    },

    lawyer10: {
        title: "원혜원",
        titleEn: "Won Hyewon",
        info: [
            {
                title: "약력",
                infoDetail: [
                    "대한변호사협회 건설전문변호사",
                    "정비사업 담당 변호사",
                    "아파트하자소송 담당 변호사",
                    "부산대학교 법학전문대학원 졸업",
                    "전) 법무법인 영동 변호사",
                    "전) 법무법인 올흔 자문 변호사",
                    //
                ],
            },
        ],
    },

    lawyer11: {
        title: "정수진",
        titleEn: "Jung Sujin",
        info: [
            {
                title: "약력",
                infoDetail: [
                    "아파트하자소송 담당 변호사",
                    "입주예정자협의회 법률 지원 변호사",
                    "한양대학교 정책학과 우수 졸업",
                    "한양대학교 법학전문대학원",
                    "헌법재판연구원 실무수습",
                    "수원지방검찰청 안양지청 실무수습",
                    "전) 법무법인 중현",
                    "전) 법무법인(유한) 대륜 본사",
                    //
                ],
            },
        ],
    },

    lawyer12: {
        title: "정지은",
        titleEn: "Chung Jieun",
        info: [
            {
                title: "약력",
                infoDetail: [
                    "아파트하자소송 담당 변호사",
                    "입주예정자협의회 법률 지원 변호사",
                    "아파트 법률 문제 자문 변호사",
                    "고려대학교 자유전공학부 정치외교학과 졸업",
                    "서울동부지방검찰청 실무수습",
                    //
                ],
            },
        ],
    },

    lawyer13: {
        title: "구경일",
        titleEn: "Ku Kyeongil",
        info: [
            {
                title: "약력",
                infoDetail: [
                    "정비사업 담당 변호사",
                    "토목기사",
                    "고려대학교 건축사회환경공학부 졸업",
                    "경북대학교 법학전문대학원 졸업",
                    "대구지방법원 하계 실무수습",
                    "전) 조달청 토목환경과 시설주사보",
                    "전) 세종특별자치시 시설주사보",
                    //
                ],
            },
        ],
    },

    lawyer14: {
        title: "박정빈",
        titleEn: "Park Jungbin",
        info: [
            {
                title: "약력",
                infoDetail: [
                    "아파트하자소송 담당 변호사",
                    "입주예정자협의회 법률 지원 변호사",
                    "손해사정사",
                    "한국외국어대학교 경제학과 졸업",
                    "아주대학교 법학전문대학원 졸업",
                    "전) 호반건설(산업) 회계팀",
                    "전) 법무법인 테헤란",
                    //
                ],
            },
        ],
    },

    lawyer15: {
        title: "배지원",
        titleEn: "Bae Jiwon",
        info: [
            {
                title: "약력",
                infoDetail: [
                    "서울대 졸업",
                    "사법시험 제43회(사법연수원 33기)",
                    "법무법인 태성 공증 변호사",
                    "인천자유구역청 토지개발 관련 소송 ",
                    "목동 하이페리온 하자 관련 소송 ",
                    "가든파이브 상가 집단 분쟁 소송",
                    "가락농수산물시장 시설현대화 사업 관련 상가(청과도매조합) 자문 및 소송",
                    "재개발.재건축 자문 및 소송",
                    "전) 강남구청 무료 상담 변호사",
                    "전) (주)엠포스 고문 변호사",
                    "전) (주)빌리어즈TV 고문 변호사",
                    "전) (주)엠피아 고문 변호사",
                    "전) (주)골프넷 고문 변호사",
                    "전) (주)웨인테크놀로지 고문 변호사",
                    "전) (주)대산 고문 변호사",
                    "전) (주)시소캐피탈 고문 변호사"
                    //
                ],
            },
        ],
    },

    lawyer16: {
        title: "박규훈",
        titleEn: "Park Kyuhoon",
        info: [
            {
                title: "약력",
                infoDetail: [
                    "대한변호사협회 건설전문변호사",
                    "대한변호사협회 재개발, 재건축전문변호사",
                    "아파트하자소송 변호사",
                    "건설이코노미뉴스 칼럼니스트",
                    "전) 팜팩토리 법률 자문 변호사",
                    "전) 예그리나 법률 자문 변호사",
                    "인하대학교 법학과 졸업",
                    "전북대학교 법학전문대학원 졸업",
                    "사법연수원 하계연수",
                    //
                ],
            },
        ],
    },
};
